<template>
    <div class="register-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="mt-5 p-3">
                        <h1>REJESTRACJA</h1>

                        <div class="form-container my-4">
                            <form method="post" @submit.prevent="beforeRegister" action="">
                                
                                <inputText v-model="form.name" name="name" label="Imię *" :error="validation.firstError('form.name')" />
                                <inputText v-model="form.surname" name="surname" label="Nazwisko *"  :error="validation.firstError('form.surname')"  />
                                
                                <inputEmail v-model="form.email" name="email" label="E-mail *" :showSpinner="validation.isValidating('form.email')" 
                                    :error="validation.firstError('form.email')" />
                                
                                <inputText v-model="form.phone" name="phone" label="Telefon" :error="validation.firstError('form.phone')" />
                                
                                <inputPassword v-model="form.password" name="password" label="Hasło *" :newPassword="true" 
                                        :error="validation.firstError('form.password')" />
                                
                                <inputPassword v-model="form.confirmpassword" name="confirmpassword" label="Powtórz hasło *" :newPassword="true" 
                                        :error="validation.firstError('form.confirmpassword')" />
                                
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="input-group col-2 no-gutters px-0 align-right">
                                            <input id="confirmterms" type="checkbox" required="true" v-model="form.confirmterms" name="confirmterms" class="" style="float: right; margin: 7px 16px; border-radius:10px; "/>
                                        </div>
                                        <div class="input-group col-9 no-gutters px-0">
                                            <label for="confirmterms" class=""> Akceptuje <router-link :to="{ name: 'Terms'}"><i>regulamin</i></router-link>.</label>
                                        </div>
                                    </div>
                                </div>

                                <dismissAlert ref="errorAlert" />

                                <div class="text-center">
                                    <buttonLoader type="submit" class="btnLong" variant="success" :isLoading="inProgress" label="Załóż konto" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-container my-2">
                            <div class="hr-sect">lub</div>
                    </div>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 text-center">
                    Masz już konto ? <router-link :to="{name: 'Login'}" >Zaloguj się</router-link>
                </div>
                <div class="col-12 text-center">
                    Zapomniałeś hasła? <router-link :to="{name: 'ForgotPassword'}" >Wygeneruj nowe</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
//import axios from "axios";

export default {
    name: "Register",
    data: () => ({
        inProgress: false,
        form: {
            name: null,
            surname: null,
            email: null,
            phone: null,
            password: null,
            confirmpassword: null
        }
    }),
    validators: {
        'form.name': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'form.surname': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'form.phone': function (value) {
            if (value)
                return Validator.value(value).maxLength(50);
        },
        'form.email': {
            cache: 'last',
            debounce: 200,
            validator: function (value) {
                let vMessage = Validator.value(value)
                    .required()
                    .minLength(5)
                    .maxLength(255)
                    .email();
                if ( vMessage._messages.length == 0 ) {
                    vMessage = Validator.custom(function () {  
                        if (!Validator.isEmpty(value)) {
                            return this.$store.dispatch('auth/checkEmailIsFree', { email: this.form.email, id: null }  )
                                .catch( ( error ) =>  {
                                    return error;
                                });
                        }
                    }.bind(this) );
                }
                return vMessage;
            }
        },
        'form.password': function (value) {
            return Validator.value(value).required().minLength(8).maxLength(255);
        },
        'form.confirmpassword': function (value) {
            if ( value )
                return Validator.value(value).minLength(8).maxLength(255).required();
        },
        'form.confirmpassword, form.password': function (valueConfirmPassword, valuePassword) {
            if (this.inProgress || this.validation.isTouched('form.confirmpassword')) {
                return Validator.value(valuePassword).required().match(valueConfirmPassword,'Hasła nie są takie same.');
            }
        }
    },
    methods: {
        beforeRegister(){
            this.inProgress = true;
            this.$validate().then( this.register );
        },
        register(success){
            if (success) {
                this.$store.dispatch('auth/register', this.form)
                    .then( () => {
                        this.$router.push( {name: "RegisterThankYou"} );
                    }).catch( error =>  {
                        this.inProgress = false;
                        console.log('error', {error});
                        this.$refs.errorAlert.alertShowDanger('Błąd tworzenia konta');
                    });
            } else {
                this.inProgress = false;
            }
        }
    }
};
</script>